<template>
<div class="app flex-row align-items-center container-wrapper" :style="container_background_image">
    <div class="container p-3">
        <b-row class="m-0 justify-content-center shadow-2">
            <b-col md="6" class="p-0 bg-white">
                <q-card no-body stretch class="bg-primary full-height d-flex wrap register-side text-center align-items-center">
                    <div class="full-width align-self-start">
                        <div class="full-width navbar-brand mb-4 mt-4">
                            <img class="navbar-brand-full" :src="appLogo()" height="100" alt="Dynamic Studio Logo">
                        </div>
                        <q-separator class="m-0" dark />
                    </div>
                    <div class="full-width pb-4 align-self-end text-white">
                        <h2 class="mt-4 mb-4 text-h4">{{ translate('welcome') }}</h2>
                    </div>
                </q-card>
            </b-col>
            <b-col md="6" class="p-0 bg-white">
                 <q-card no-body class="p-4 full-height register-form" light>
                    <AppHeader class="header-lang pl-3 pr-1 mb-4" :style="{position: 'relative', marginTop: '-1rem'}">
                        <div class="text-muted font-weight-bold">{{ translate('selected_language') }}</div>
                        <switch-lang></switch-lang>
                    </AppHeader>
                    <b-form @submit.prevent="activateAccount">
                        <h1 class="text-h4 text-uppercase text-center font-weight-bold text-primary">{{translate('activate_account')}}</h1>
                        <template v-if="$route.query['require_birth_date'] && $route.query['require_birth_date'] === '1'">
                            <p>
                                {{ translate('translate_v1_user_account_reactivation_info_text').replace('%the_terms_and_conditions%', '') }}
                                <router-link :to="{name: 'TermsAndConditions'}">{{ translate('the_terms_and_conditions') }}</router-link>
                            </p>
                        </template>

                        <b-input-group class="mb-3" v-if="$route.query['require_birth_date'] && $route.query['require_birth_date'] === '1'">
                            <q-input @focus="$refs.qDateProxy.show()" @click="$refs.qDateProxy.show()" @input="$refs.qDateProxy.show()" light square outlined color="primary" :placeholder="translate('birthday')" dense class="full-width" id="birth_date" v-model="newUser.birth_date" no-error-icon :error="submitted && !newUser.birth_date" :error-message="''">
                                <template v-slot:append>
                                    <q-icon name="event" class="cursor-pointer pointer-events-none">
                                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                            <q-date first-day-of-week="1" v-model="newUser.birth_date" mask="YYYY-MM-DD" @input="(val) => onUpdateDate(newUser.birth_date)" />
                                        </q-popup-proxy>
                                    </q-icon>
                                </template>
                            </q-input>
                        </b-input-group>

                        <b-input-group class="mb-3">
                            <q-select light square color="primary" dense outlined v-model="newUser.gender" :options="gender_options" map-options :label="translate('gender')" no-error-icon :error="submitted && $v.newUser.gender.$error" :error-message="''" />
                        </b-input-group>

                        <b-input-group class="mb-3">
                            <q-input @input="password_length = newUser.password.length" autocomplete="current-password" light square dense outlined :error-message="''" no-error-icon :error="submitted && $v.newUser.password.$error" color="primary" type="password" v-model="newUser.password" :label="($route.query['require_birth_date'] && $route.query['require_birth_date'] === '1' ? translate('new_password') : translate('password')) + (password_length > 0 ? ': ' + password_length + ' ' + translate('characters') : '')">
                                <template v-slot:append>
                                    <q-icon name="icon-lock" />
                                </template>
                            </q-input>
                            <div class="text-muted small">
                                {{ translate('please_enter_password_with_limitations') }}
                            </div>
                            <div v-if="submitted && $v.newUser.password.$error" class="invalid-feedback">
                                <span v-if="!$v.newUser.password.password">Password is invalid</span>
                            </div>
                        </b-input-group>

                        <b-input-group class="mb-3">
                            <q-input autocomplete="current-password" light square dense outlined :error-message="''" no-error-icon :error="submitted && $v.newUser.password_confirmation.$error" color="primary" type="password" v-model="newUser.password_confirmation" :label="translate('confirm_password')">
                                <template v-slot:append>
                                    <q-icon name="icon-lock" />
                                </template>
                            </q-input>
                            <div v-if="submitted && $v.newUser.password_confirmation.$error" class="invalid-feedback">
                                <span v-if="!$v.newUser.password_confirmation.password">Confirm Password is invalid</span>
                                <span v-else-if="!$v.newUser.password_confirmation.sameAsPassword">Passwords must match</span>
                            </div>
                        </b-input-group>

                        <b-input-group class="mb-3">
                            <div class="row ml-0 mr-0 mb-1">
                                <div class="col-md p-0">
                                    <q-checkbox dense v-model="newUser.receive_emails" color="primary">
                                        {{ translate('receive_emails_is_mandatory') }}
                                    </q-checkbox>
                                    <!-- tac: with_validation -->
                                    <q-field ref="toggleTac" class="p-0 m-0" :value="newUser.tac" :rules="[tacRule]" borderless dense>
                                        <template v-slot:control>
                                            <q-checkbox style="margin-bottom: - 1rem;" dense v-model="newUser.tac" color="primary">
                                                {{ translate('terms_and_conditions_agreement').replace('%the_terms_and_conditions%', '') }}
                                                <router-link :to="{name: 'TermsAndConditions'}">{{ translate('the_terms_and_conditions') }}</router-link>
                                            </q-checkbox>
                                        </template>
                                    </q-field>
                                </div>
                            </div>
                        </b-input-group>
                        <div class="mb-3 text-center" v-if="!button">
                            <q-btn icon-right="send" color="info" square no-caps type="submit" class="no-text-decoration icon-right-leftspace">{{ translate('activate') }}</q-btn>
                            <q-item v-if="msg" class="bg-red-2 border-top-red mt-3 p-2" dense>
                                <div value="no" :submit="submit == 'yes' ? 'submit' : ''">{{ msg }}</div>
                            </q-item>
                        </div>
                    </b-form>
                    <div v-if="button" class="text-center mt-3">
                        {{ translate('account_activated_login_account') }}
                        <br />
                        <q-btn icon-right="login" :to="{name: 'Login'}" color="dark" square no-caps type="button" class="no-text-decoration icon-right-leftspace">{{ translate('login') }}</q-btn>
                    </div>
                 </q-card>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
import {
    eventBus
} from '../../main'
import {
    Header as AppHeader
} from '@coreui/vue'
import {
    required,
    email,
    minLength,
    sameAs
} from "vuelidate/lib/validators"
import LangNavbarAuth from './LangNavbarAuth'

export default {
    name: 'ActivateAccount',
    components: {
        AppHeader,
        'switch-lang': LangNavbarAuth
    },
    data: function () {
        return {
            password_length: 0,
            newUser: {
                tac: false,
                gender: null,
                password: null,
                confirmation_password: null,
                receive_emails: false
            },
            msg: '',
            submit: 'no',
            button: false,
            submitted: false,
            selected_language: 'ro',
            success: false,
        }
    },
    validations: {
        newUser: {
            tac: {
                required
            },
            gender: {
                required
            },
            password: {
                required
            },
            password_confirmation: {
                required,
                sameAsPassword: sameAs('password')
            }
        }
    },
    mounted: function () {
        eventBus.$on('current_language', (lang) => {
            this.setSelectedLanguage(lang)
        })
    },
    computed: {
        random_background: function () {
            var array = ['https://img5.goodfon.com/original/1920x1080/8/fd/sportivnaia-para-zal-trenazhernyi-zal-sport-tiaga-ganteli-pl.jpg', '/img/backgrounds/background_login.jpg']
            var random = this.$shuffle(array)

            return random[0]
        },
        container_background_image: function () {
            return 'background-image: url(' + this.random_background + ');'
        },
        gender_options: function () {
            return [{
                    value: 'f',
                    label: this.translate('gender_female')
                },
                {
                    value: 'm',
                    label: this.translate('gender_male')
                },
            ]
        }
    },
    methods: {
        onUpdateDate: function (newValue) {
            this.$nextTick(function () {
                this.$refs.qDateProxy.hide()
            })
        },
        setSelectedLanguage: function (lang) {
            this.selected_language = lang
        },
        tacRule: function (val) {
            return val || ''
        },
        validateTacRule: function () {
            return this.$refs.toggleTac.validate()
        },
        activateAccount: function () {
            this.submitted = true
            this.$v.$touch()
            this.validateTacRule()
            if (this.$v.$invalid || this.validateTacRule() === false) {
                return false
            }
            if (this.newUser) {
                if (this.newUser.gender && this.newUser.gender.value) {
                    this.newUser.gender = this.newUser.gender.value
                }
                if (this.selected_language) {
                    this.newUser.lang = this.selected_language
                }
                var params = {
                    gender: this.newUser.gender,
                    password: this.newUser.password,
                    email: this.$route.query['email'],
                    activation_token: this.$route.query['activation_token'],
                    receive_emails: this.newUser.receive_emails,
                    lang: this.newUser.lang,
                }
                if (this.$route.query['require_birth_date']) {
                    params['birth_date'] = this.newUser.birth_date
                }
                var url = baseUrl + 'auth/activate-v1-user'
                axios.post(url, params)
                    .then(response => {
                        var message = ''
                        if (response.data.status) {
                            this.msg = null
                            var activated_message = this.translate('account_activated')
                            this.$toasted.success(activated_message, {
                                duration: this.$toasted_duration
                            })
                            if (this.submit === 'no') {
                                this.submit = 'yes'
                                this.button = true
                                localStorage.setItem('makeUser', this.submit)
                            }
                            this.success = true
                            message = 'account_activated_success'
                        } else {
                            message = "account_activated_" + response.data.msg
                            this.success = false

                            this.msg = this.translate(message)
                            this.$toasted.error(this.msg, {
                                duration: this.$toasted_duration
                            })
                        }
                    })
                    .catch(error => {
                        this.$toasted.error(error.response.data.msg, {
                            duration: this.$toasted_duration
                        })
                    })
            }

        }
    }
}
</script>
